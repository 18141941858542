



















































import { Component, Vue } from 'vue-property-decorator';
import myHeader from '@/components/Header.vue';
import myFooter from '@/components/Footer.vue';

import { getQuesInfo, postQuesInfo } from '@/api/courseWork/courseWork';

Component.registerHooks([
    'beforeRouteLeave'
]);
@Component({
    components: {
        myHeader,
        myFooter
    }
})
export default class AnswerSheet extends Vue {
  // textAnswer: any;
  data = {
      // eslint-disable-next-line @typescript-eslint/camelcase
      ques_id: 0
  };

  // 题目数据
  questionsData = [{
      // eslint-disable-next-line @typescript-eslint/camelcase
      children: [{ is_selected: true }],
      // eslint-disable-next-line @typescript-eslint/camelcase
      has_answer: true
  }];

  // 问卷数据
  question = {
      id: true,
      name: true,
      cls_id: true
  };

  // 课程名字
  crsName = '';
  // 节名字
  sectionName = '';
  // loadding状态
  loadingIndex = true;

  mounted() {
      const scheckSemester = JSON.parse(localStorage.getItem('checkSemester') as string);
      this.getData();
  }

  // 方法
  getData() {
      // eslint-disable-next-line @typescript-eslint/camelcase
      this.data.ques_id = parseInt(this.$route.query.quesId as string);

      getQuesInfo(this.data).then((res: any) => {
          this.loadingIndex = false;
          this.loadingIndex = false;
          this.questionsData = res.data;
          this.question = res.question;
      }).catch((error: any) => {
          console.log(error);
      });
  }

  changeChoice(index: number, subscript: number) {
      for (let i = 0; i < this.questionsData[index].children.length; i++) {
      // eslint-disable-next-line @typescript-eslint/camelcase
          this.questionsData[index].children[i].is_selected = false;
      }
      this.questionsData[index].children[subscript].is_selected = true;
  }

  goAnswerReport() {
      const data = {
          quesList: this.questionsData,
          ques_id: this.question.id,
          cls_id: this.question.cls_id
      };

      postQuesInfo(data).then((res: any) => {
          console.log(res);
          if (res.code === 200) {
              console.log(res);
              this.$message.success('提交成功！感谢您的配合');
              this.$router.push({
                  path: '/questionnaire'
              });
          } else {
              console.log(res.error);
              this.$message.error(res.error);
          }
      });
      console.log(data);
  }
}
